html {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

body {
  margin: 0;
  min-width: 320px;
  max-width: 500px;
  height: 100%;
  background-color: #fff;
  font-family: Lucida Sans, Lucida Sans Regular, Lucida Grande,
    Lucida Sans Unicode, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}
textarea {
  max-width: 100%;
}
p {
  margin: 0;
}
img {
  max-width: 100%;
  height: auto;
}
.container {
  padding: 0 10px;
}
.btn {
  cursor: pointer;
  transition: 0.4s;
}
.btn:active {
  transform: scale(0.9);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
